import dynamic from 'next/dynamic';

const StorageSolutionCard = dynamic(() => import('./storage-solution-card'));

const SafeStorageSolutions = (props: any) => {
  const { title, cards, hostUrl } = props;

  return (
    <section className="safe-storage sv-safe-car-storage">
      <div className="container">
        <h2 className="section-heading">{title}</h2>
        <div className="solution-cards">
          <div className="row justify-content-center g-3">
            {cards &&
              cards.map((card: any) => (
                <StorageSolutionCard
                  key={card.id}
                  card={card}
                  hostUrl={hostUrl}
                ></StorageSolutionCard>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SafeStorageSolutions;
